.quote-item
    margin-top: 15px
    padding: 10px
    font-size: 16px
    line-height: 24px
    text-align: justify
    font-style: italic
    &__icons
        display: flex
        justify-content: center
        div
            width: 100px
            display: flex
            justify-content: space-between