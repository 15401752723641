.list
    width: 70%
    margin: 0 auto
    &__empty
        font-size: 18px
        text-align: center
        font-style: italic
        color: silver

@media ( max-width: 768px )
    .list
        width: 100%