.notes
    &__title
        margin-top: 70px
        margin-bottom: 0
        text-align: center
    &__form
        width: 70%
        margin: 0 auto
        &_input
            width: 100%
            height: 33px
            border: none
            border-bottom: 1px solid darkgrey
            padding: 10px
            &:focus
                border: 1px solid #bfd2d2
                border-radius: 15px
                outline: none

@media ( max-width: 768px )
    .notes__form
        width: 100%