.filter
    display: flex
    margin: 30px auto
    justify-content: space-between
    width: 70%
    &__btn
        width: 150px
        height: 40px
        border: none
        background-color: #e8eded
        border-radius: 15px 
        &:hover
            background-color: #222222
            color: white
        &:focus
            background-color: darkgrey
            color: white
            font-weight: bold
.search
    width: 70%
    margin: 0 auto
    position: relative
    &__input
        width: 100%
        height: 33px
        border: none
        border-bottom: 1px solid darkgrey
        padding: 10px
        &:focus
            border: 1px solid #bfd2d2
            border-radius: 15px
            outline: none
    &__icon
        position: absolute
        top: 2px
        right: 10px
        font-size: 20px
        color: silver
        cursor: pointer


@media ( max-width: 1119px )
    .filter__btn
        width: 120px

@media ( max-width: 768px )
    .filter
        width: 100%
        margin: 80px auto 20px
        display: grid
        grid-template-columns: repeat(auto-fit, 150px)
        gap: 20px
        justify-content: center
    .search
        width: 100%