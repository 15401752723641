@import '../../style/style.sass'

.author
    width: 90%
    margin: 30px auto 10px
    &__wrapper
        display: grid
        grid-template-columns: 35% 60%
        column-gap: 5%
        padding: 10px
        &_photo
            width: 400px
            height: 70vh
            object-fit: contain
    &__name
        font-size: 32px
        font-weight: bold
    &__country
        font-size: 20px
    &__description
        margin-top: 30px
        font-size: 24px
        text-align: justify
    &__icons
        margin: 15px auto
        width: 200px
        display: flex
        justify-content: space-between
        font-size: 18px
    

@media ( max-width: 991px )  //1119
    .author
        &__wrapper_photo
            width: 300px
        &__name
            font-size: 26px
        &__description
            margin-top: 15px
            font-size: 20px


@media ( max-width: 830px ), ( min-width: 1400px )
    .author
        &__wrapper
            display: flex
            flex-direction: column
            align-items: center
            &_photo
                margin-top: 15px
                width: 280px
                height: 420px
                order: 1
            &_text
                text-align: center
                order: 0
        &__name
            font-size: 24px
        &__country
            font-size: 16px
        &__description
            width: 95%
            font-size: 18px


@media ( max-width: 425px )
    .author
        &__wrapper_photo
            width: 95%
            height: 100%
            object-fit: cover
        &__name
            font-size: 22px
        &__description
            font-size: 16px